<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          Staff Members
        </strong>
      </div>
      <b-table
        small
        :fields="fields"
        :items="staffs"
        responsive="sm"
        selectable
        select-mode="single"
        @row-clicked="handleRowClick"
      >
        <template #cell(action)="data">
          <hwa-button
            :icon="data.item.active?'UserXIcon':'UserCheckIcon'"
            :icon-only="true"
            :variant="data.item.active?'flat-danger':'flat-success'"
            @onClick="updateStaff(data.item)"
          />
        </template>
        <template #cell(roles)="data">
          <div
            v-for="(role,index) in data.item.roles"
            :key="index"
            class="px-1"
          >
            <b-badge variant="light-success">
              {{ role.name }}
            </b-badge>
          </div>
        </template>
        <template #cell(login_access)="data">
          <b-badge :variant="data.item.can_login?'light-success':'light-danger'">
            {{ data.item.can_login?"Enabled":"Disabled" }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge variant="light-success">
            {{ data.item.status==='active'?"Active":"Inactive" }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
    <validation-observer
      ref="staffForm"
      v-slot="{invalid}"
    >
      <hwa-modal
        :title="`Staff`"
        :show="staffModalOpened"
        size="md"
        hide-footer
        :is_data_change="is_staff_change"
        @onClose="closeStaffModal()"
      >
        <div class="row">
          <div class="col-md-6"> <hwa-input
            v-model="staff.first_name"
            name="name"
            label="First Name"
            placeholder="Enter first name"
            rules="required"
          /></div>
          <div class="col-md-6">   <hwa-input
            v-model="staff.last_name"
            name="name"
            label="Last Name"
            placeholder="Enter last name"
            rules="required"
          />
          </div>
          <div class="col-md-6">
            <hwa-input
              v-model="staff.email"
              name="name"
              label="Email"
              placeholder="Enter email"
              rules="required"
              type="email"
            />

          </div>
          <div class="col-md-6">
            <hwa-input
              v-model="staff.phone"
              name="name"
              label="Phone Number"
              placeholder="Enter phone number"
            />
          </div>
        </div>
        <div class="row mt-3">
          <!-- <div class="col-md-3">
            <b-form-group label="Status">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                :checked="staff.status === 'active'? true : false"
                @change="setActiveUser(staff)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </div> -->
          <div class="col-md-6">
            <b-form-group label="Login Access">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                :checked="Boolean(staff.can_login) === true ? true : false"
                @change="setLoginAccess(staff)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="UserCheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="UserXIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <div
          v-if="$can('assign-roles','hwa')"
          class="row mt-2"
        >
          <div class="col-12">
            <strong>User Roles</strong>
          </div>
        </div>
        <div
          v-if="$can('assign-roles','hwa')"
          class="row mt-1 align-items-center justify-content-between"
        >
          <div class="col-12">
            <!-- <hwa-multi-select
              v-model="selectedRoles"
              name="role"
              rules="required"
              placeholder="Select Role"
              label-name="name"
              :options="roles"
            /> -->
            <multiselect
              v-model="selectedRoles"
              :options="roles"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Add new role"
              label="name"
              track-by="name"
              :preselect-first="true"
              :hide-selected="true"
              @input="selectRole"
              @remove="removeRole"
            />
          </div>
          <!-- <div class="col-3">
            <hwa-button
              :icon-only="true"
              variant="outline-success"
              @onClick="assignRoles()"
            />
          </div> -->
        </div>
        <div
          v-if="$can('update-staff-information','hwa')"
          class="d-flex mt-3 justify-content-between"
        >
          <hwa-button
            variant="secondary"
            icon="XIcon"
            class="mr-1"
            :class="is_staff_change === true ? 'active-secondary' : ''"
            label="Cancel"
            @onClick="closeStaffModal()"
          />
          <hwa-button
            variant="success"
            :label="updateStaffMode?'Save':'Save'"
            :class="is_staff_change === true ? 'active-success' : ''"
            :disable="invalid || is_staff_change === false ? true : false"
            :loading="staffLoading"
            loading-text="Loading..."
            @onClick="updateStaff()"
          />
        </div>
      </hwa-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BTable, BCard, BBadge, BFormGroup, BFormRadioGroup, BRow, BFormCheckbox,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver } from 'vee-validate'
import HwaInput from '@/layouts/components/HwaInput.vue'
import HwaModal from '@/layouts/components/HwaModal.vue'
import confirm from '@/mixins/confirm'
import HwaMultiSelect from '@/layouts/components/HwaMultiSelect.vue'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    HwaMultiSelect,
    HwaModal,
    HwaInput,
    HwaButton,
    BTable,
    BFormCheckbox,
    BCard,
    BBadge,
    ValidationObserver,
    BFormGroup,
    BFormRadioGroup,
    BRow,
    Multiselect,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      fields: [{ key: 'full_name', label: 'Full Name',sortable:true }, {key:'email',label:'Email',sortable:true}, 'phone', { key: 'login_access', label: 'Login Access', sortable: true }, { key: 'status', label: 'Status', sortable: true }, { key: 'roles', label: 'Roles', sortable: true }],
      staffs: [],
      staff_name: '',
      staff_id: null,
      staffLoading: false,
      updateStaffMode: false,
      staffModalOpened: false,
      selectedStaff: { },
      selectedRoles: [],
      staff: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        can_login: true,
        status: 'active',
      },
      roles: [],
      teams: [],
      is_staff_change: false,
    }
  },
  watch: {
    staff: {
      handler() {
        this.is_staff_change = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getStaffs()
    this.getRoles()
  },
  methods: {
    setActiveUser(data) {
      if (data.status === 'active') {
        this.staff.status = 'inactive'
      } else {
        this.staff.status = 'active'
      }
    },
    setLoginAccess(data) {
      if (Boolean(data.can_login) === true) {
        this.staff.can_login = false
      } else {
        this.staff.can_login = true
      }
    },
    assignRoles() {
      if (!this.selectedRoles.length) {
        return this.showInfo('A user must have at least one role')
      }
      this.$http.post('/api/assign-roles', { role_id: this.selectedRoles.map(r => r.id), staff_id: this.staff.id })
        .then(res => {
          this.staffs = this.staffs.map(c => {
            if (c.id === res.data.id) {
              c.roles = res.data.roles
              return c
            }
            return c
          })
        })
        .catch(err => {
          this.showError('Failed to retrieve staffs')
        })
    },
    removeRole() {
      this.assignRoles()
    },
    selectRole() {
      this.assignRoles()
    },
    openStaffModal() {
      this.updateStaffMode = false
      this.staffModalOpened = true
    },
    closeStaffModal() {
      if (this.is_staff_change) {
        this.$swal({
          title: 'Do you want to save changes?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save changes!',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.staffModalOpened = false
            this.is_staff_change = false

            this.updateStaff()
          } else if (result.dismiss === 'cancel') {
            this.staffModalOpened = false
            this.is_staff_change = false
          }
        })
      } else {
        this.staffModalOpened = false
        this.is_staff_change = false
      }
    },
    updateStaff() {
      this.staffLoading = true
      this.$http.put('/api/update-user-account', this.staff)
        .then(res => {
          this.staffLoading = false
          this.staffs = this.staffs.map(c => {
            if (c.id === res.data.id) {
              c.full_name = res.data.full_name
              c.email = res.data.email
              c.can_login = res.data.can_login
              c.status = res.data.status
              c.phone = res.data.phone
              return c
            }
            return c
          })
          this.showSuccess('Updated successfully')
          this.staffModalOpened = false
          this.is_staff_change = false
          this.getStaffs()
        })
        .catch(err => {
          this.staffLoading = false
          this.showError('Failed to update staffs')
        })
    },
    handleRowClick(row) {
      if (row) {
        this.staff = { ...row }
        this.selectedRoles = this.staff.roles
        this.updateStaffMode = true
        this.staffModalOpened = true
        const dom = this
        setTimeout(() => {
          dom.is_staff_change = false
        }, 100)
      }
    },
    getStaffs() {
      this.$http.get('/api/only-staff-members')
        .then(res => {
          this.staffs = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve staffs')
        })
    },
    getRoles() {
      this.$http.get('/api/role/roles')
        .then(res => {
          this.roles = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve roles')
        })
    },
    // getTeams() {
    //   this.$http.get('/api/staff/teams')
    //     .then(res => {
    //       this.teams = res.data
    //     })
    //     .catch(err => {
    //       this.showError('Failed to retrieve teams')
    //     })
    // },
  },
}
</script>
